import Vue from 'vue'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import VueRouter from 'vue-router'

import Form from './views/Form.vue'
import Preview from './views/Preview.vue'
import App from "./App.vue";

import Modal from './components/Modal.vue'
Vue.component('Modal', Modal)

Vue.use(VueRouter);
Vue.use(Vuelidate)
Vue.use(Notifications);

Vue.config.productionTip = false

const routes = [
    { path: '/form', name: 'form', component: Form, props: true },
    { path: '*', redirect: '/form' },
    { path: '/preview', name: 'preview', component: Preview , props: true},
]
const router = new VueRouter({
    routes
})

window.documentCommerciauxVueApp = function(params) {
    console.log(params)
    Vue.prototype.$jeton = params.jeton
    Vue.prototype.$dossierType = params.dossierType
    Vue.prototype.$company_id = params.company_id
    Vue.prototype.$admin = params.admin
    Vue.prototype.$document_type = params.document_type ? params.document_type : "MANDAT"
    return new Vue({
        router,
        render: h => h(App, { }),
    });
}

if (document.querySelector('.documents-commerciaux-app')) {
    //window.documentCommerciauxVueApp({dossierId: process.env.VUE_APP_DOSSIER, jeton: process.env.VUE_APP_JETON})
    //window.documentCommerciauxVueApp({dossierId: 10453, jeton: '482f6ef2b0d57d2efe0729cbafd6a42c'})
    /*window.documentCommerciauxVueApp({
        jeton: 'fe8312eaf917cdac48ddb0d0ed14712d',
        dossierType: 'PARTICULIER',
        hybrideMandant: false,
    })  .$mount('.documents-commerciaux-app')*/

    window.documentCommerciauxVueApp({
        jeton: 'cf2cfde4ddd3d0e97b0a00eb94d7caaa',
        dossierType: 'PARTICULIER',
        company_id: 5,
        admin: "1",
        document_type: "NOTICE",
    })  .$mount('.documents-commerciaux-app')
}


/**
 *  MANDAT simple:
    jeton: 'dd88b30e62788b6539983633f5734a3c',
    dossierId: 10370

    MANDAT +2 emp:
    jeton: 'fe8312eaf917cdac48ddb0d0ed14712d',
    dossierId: 2188
 *
 */
