<template>
    <modal>
        <template slot="header">Souhaitez-vous vraiment supprimer ce paragraphe ?</template>
        <template slot="content">
            <div class="content">
                <div v-html="paragraphe.paragraphe"></div>
            </div>
        </template>
        <template slot="footer">
            <a
                    class="btn btn-transparent"
                    target="_blank"
                    @click="close"
            >
                Annuler
            </a>
            <a
                    class="btn btn-transparent"
                    target="_blank"
                    @click="paragrapheSupprimer(paragraphe.id)"
            >
                Supprimer
            </a>
        </template>
    </modal>
</template>

<script>

    export default {
        name: 'ModalSupprimer',
        props: {
            close: Function,
            paragraphe: Object,
            paragrapheSupprimer: Function
        },
        data() {
            return {}
        },
        created() {

        },
        methods: {}
    }
</script>

<style lang="scss" scoped>
    a.btn {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        margin: 0 !important;
    }

    .content {
        overflow: auto;
    }

</style>
