import axios from 'axios'

class Api {

    constructor(jeton) {
        this.jeton = jeton
        this.axios = axios.create({
            transformResponse: [function (data, headers) {
                if (headers['content-type'] === 'application/json') {
                    const fn = JSON.parse || JSON.decode
                    return fn(data);
                }
                return data
            }],
            transformRequest: [function (data) {
                try {
                    return JSON.stringify(data)
                } catch {
                    return JSON.encode(data)
                }
            }],
        })
    }

    pdfCreer(dossierId, dossierType) {
        return this.axios.post(process.env.VUE_APP_API + '/documents/generer', {
            "dossier_id": dossierId,
            "document_type": dossierType
        }, {headers: {jeton: this.jeton}})
    }

    pdfMetaCharger(dossierId, documentType) {
        return this.axios.get(
            process.env.VUE_APP_API + '/' + dossierId + '/documents/' + documentType + '/document_meta',
            {headers: {jeton: this.jeton}})
    }

    documentCharger(dossierId, documentType) {
        return this.axios.get(
            process.env.VUE_APP_API + '/dossiers/' + dossierId + '/documents/' + documentType,
            {headers: {jeton: this.jeton}})
    }

    previewCharger(documentType, dossierType, company_id) {
        return this.axios.post(
            process.env.VUE_APP_API + '/dossiers/documents/preview',
            {dossierType: dossierType,
                documentType: documentType,
                company_id: company_id},
            {headers: {jeton: this.jeton}})
    }

    /**
     * Document template
     */

    editionCharger(documentType) {
        return this.axios.get(
            `${process.env.VUE_APP_API}/document-templates/${documentType}/activer`,
            {headers: {jeton: this.jeton}}
        )
    }


    editionActiver(documentType) {
        return this.axios.post(
            `${process.env.VUE_APP_API}/document-templates/${documentType}/activer`,
            {},
            {headers: {jeton: this.jeton}}
        )
    }

    paragraphesInitialiser(documentType, modele, company_id, dossierType) {
        return this.axios.post(
            `${process.env.VUE_APP_API}/document-templates/${documentType}/${dossierType}/paragraphes/init/${company_id}`,
            modele,
            {headers: {jeton: this.jeton}}
        )
    }

    paragrapheAjouter(documentType, dossierType, company_id, contenu) {
        return this.axios.post(
                `${process.env.VUE_APP_API}/document-templates/${documentType}/${dossierType}/paragraphes/${company_id}`,
                contenu,
                {headers: {jeton: this.jeton}}
            )

    }

    paragraphesCharger(documentType, dossierType, company_id) {
        return this.axios.get(
                `${process.env.VUE_APP_API}/document-templates/${documentType}/${dossierType}/paragraphes/${company_id}`,
                {headers: {jeton: this.jeton}}
                )
    }

    paragrapheChanger(documentType, paragraphe) {
        return this.axios.put(
            `${process.env.VUE_APP_API}/document-templates/${documentType}/paragraphe/${paragraphe.id}`,
            paragraphe,
            {headers: {jeton: this.jeton}}
        )
    }

    paragrapheSupprimer(documentType, id) {
        return this.axios.delete(
            `${process.env.VUE_APP_API}/document-templates/${documentType}/paragraphes/${id}`,
            {headers: {jeton: this.jeton}}
        )
    }

    paragraphesDeplacer(documentType, paragraphes, dossierType, company_id) {
        return this.axios.put(
            `${process.env.VUE_APP_API}/document-templates/${documentType}/${dossierType}/paragraphes/deplacer/${company_id}`,
            paragraphes,
            {headers: {jeton: this.jeton}}
        )
    }

    modeleCreer(data, dossierType, document_type){
        return this.axios.post(
            `${process.env.VUE_APP_API}/document-modeles/` + dossierType + '/' + document_type,
            data,
            {headers: {jeton: this.jeton}}
        )
    }

    modelesCharger(document_type){
        return this.axios.get(
            `${process.env.VUE_APP_API}/document-modeles/` + document_type,
            {headers: {jeton: this.jeton}}
        )
    }

    variablesCharger(){
        return this.axios.get(
            `${process.env.VUE_APP_API}/dossiers/documents/variables`,
            {headers: {jeton: this.jeton}}
        )
    }

    companyCharger(company_id){
        return this.axios.get(
            `${process.env.VUE_APP_API}/utilisateurs/company/${company_id}`,
            {headers: {jeton: this.jeton}}
        )
    }
}

export default Api
