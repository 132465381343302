<template>
    <modal>
        <template slot="header">Enregistrer le document comme modèle</template>
        <template slot="content">
            <div class="content">
                <b>Enregistrement du document en cours comme modèle</b>
                <p>L'ensemble des paragraphes du document en cours seront enregistrés en tant que Modèle afin de pouvoir
                    répliquer pour le mandat d'un autre Courtier</p>

                <form>
                    <div class="form-group" :class="{ 'form-erreur': $v.nom.$error }">
                        <label>Titre *</label>
                        <input
                                class="form-control"
                                type="text"
                                name="titre"
                                placeholder="Titre"
                                required
                                v-model.trim="$v.nom.$model"
                        />
                        <i v-if="!$v.nom.required">Champ requis</i>
                    </div>
                    <div class="form-group" :class="{ 'form-erreur': $v.reference.$error }">
                        <label>Référence *</label>
                        <small>
                            <i>La référence est un mot clé unique permettant d'identifier facilement les paragraphes du
                                modèle (ex: STANDARD2020, NBCOURTAGE, MADEINCOURTAGE)</i>
                        </small>
                        <input
                                class="form-control"
                                type="text"
                                name="reference"
                                placeholder="Référence"
                                v-model.trim="$v.reference.$model"
                                required
                        />
                        <i v-if="!$v.reference.required">Champ requis</i>
                    </div>
                    <div class="form-group" :class="{ 'form-erreur': $v.description.$error }">
                        <label>Description *</label>
                        <textarea
                                class="form-control"
                                name="description"
                                placeholder="Description"
                                v-model.trim="$v.description.$model"
                        ></textarea>
                        <i v-if="!$v.description.required">Champ requis</i>
                    </div>
                </form>

            </div>
        </template>
        <template slot="footer">
            <a
                    class="btn btn-transparent"
                    target="_blank"
                    @click="annuler"
            >
                Annuler
            </a>
            <a
                    class="btn btn-transparent"
                    target="_blank"
                    @click="enregistrer"
            >
                Enregistrer
            </a>
        </template>
    </modal>
</template>

<script>
    const { required } = require('vuelidate/lib/validators')
    export default {
        name: 'ModalModelSauvegarder',
        props: {
            annuler: Function,
            valider: Function,
        },
        validations: {
            nom: {required},
            reference: {required},
            description: {required},
        },
        data() {
            return {
                nom: '',
                reference: '',
                description: ''
            }
        },
        methods: {
            enregistrer() {
                this.$v.$touch()
                if(this.$v.$invalid){
                    return
                }
                this.valider({
                    nom: this.nom,
                    reference: this.reference,
                    description: this.description
                })
            }
        }

    }
</script>

<style lang="scss" scoped>

    .form-group {
        margin: 15px 0;
    }

    .form-erreur {
        color: red;

        i {
            font-size: .75rem;
        }

        input, textarea {
            box-shadow: inset 0 0 0 1px red;
        }
    }

</style>
