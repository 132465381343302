<template>
    <div class="variables">
        <h2>VALEURS DYNAMIQUES
            <i data-v-69d9cabe="" title="Les valeurs dynamyques vous permettent d'inclure des informations spécifiques à chaque dossier client" class="fas fa-info-circle infobulle"></i>
        </h2>
        <ul>
            <li
                    v-for="variable of variables"
                    :key="variable"
                    @click="ajouterVariable(variable)"
            >
                {{variable}}
                <i data-v-69d9cabe="" v-bind:title="variables_infos[variable]" class="fas fa-info-circle infobulle"></i>
            </li>
        </ul>
    </div>
</template>


<script>

    export default {
        name: 'Variables',
        props: {
            variables: Array,
            variables_infos: Array,
            ajouterVariable: Function
        },
    }

</script>

<style lang="scss" scoped>
    .variables {
        width: 30%;
        font-size: .9rem;

        h2 {
            padding: 15px;
            border: 1px solid #eee;
            margin: 0;
            font-size: 1.3rem;
            padding-left: 15%;
            color: rgb(66, 66, 66);
        }

        ul {
            list-style: none;
            padding: 0;
            border: 1px solid #eee;
            border-top: 0;
            margin: 0;
            overflow: auto;
            height: 500px;
        }

        li {
            padding: 22px 15px;
            cursor: pointer;
            word-break: break-word;
            border-bottom: solid 1px #eee;
            height: 60px;

            &:hover {
                background: rgb(96, 125, 139);
                color: rgb(255, 255, 255)
            }
        }

        margin-left: 15px;

        .infobulle {
            margin-left: 20px;
        }
    }
</style>
