<template>

    <div class="modal-container">
        <div class="modal-content">

            <header class="cadre-tete cadre-tete--menu" :class="type">
                <slot name="header"></slot>
            </header>
            <main class="cadre-content">
                <slot name="content"></slot>
            </main>
            <div v-if="!noFooter"
                    class="cadre-pied"
                :class="type">
                <slot name="footer"></slot>
            </div>

        </div>
        <div class="modal-background"></div>
    </div>

</template>

<script>
  export default {
    name: 'Modal',
    props: {
      type: String,
      noFooter: Boolean
    },
  }
</script>

<style lang="scss" scoped>

    @import "~courtisia-style/scss/colors";

    .modal-content {
        overflow-y: auto !important;
    }

    .modal-background {
        position: fixed !important;
        height: 100%;
        width: 100%;
        top: 40px !important;
        left: 0;
        background: rgba(0, 0, 0, .8) !important;
    }

    .cadre-tete--menu {
        &.error{
            color: $error !important;
            border-bottom-color: $error !important;
        }
    }

    .cadre-pied {
        height: inherit !important;
        display: flex;
        button:last-child {
            margin-right: 0;
            margin-left: auto;
        }
        background: $primary;

        &.error {
            background: $error;
        }
    }
</style>
