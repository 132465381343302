<template>
    <modal>
        <template slot="header">Ajouter un paragraphe</template>
        <template slot="content">
            <div class="saisie-content">
                <div class="editeur content">
                    <quill-editor
                            ref="editeur"
                            :content="content"
                            @change="($event) => editeurChanger($event.html)"
                    ></quill-editor>
                </div>
                <Variables
                        :variables="variables"
                        :variables_infos="variables_infos"
                        :ajouter-variable="ajouterVariable"
                ></Variables>
            </div>
        </template>
        <template slot="footer">
            <a
                    class="btn btn-transparent"
                    target="_blank"
                    @click="close"
            >
                Annuler
            </a>
            <a
                    class="btn btn-transparent"
                    target="_blank"
                    @click="enregistrer"
            >
                Enregistrer
            </a>
        </template>
    </modal>
</template>

<script>

    import Quill from 'quill/dist/quill.min.js'
    import {quillEditor} from 'vue-quill-editor'
    import Variables from "../components/Variables";

    export default {
        name: 'ModalAjouter',
        props: {
            close: Function,
            paragrapheAjouter: Function,
            variables: Array,
            variables_infos: Array,
        },
        components: {
            Variables,
            quillEditor,
        },
        data() {
            return {
                content: ''
            }
        },
        methods: {
            editeurChanger(html) {
                this.content = html;
            },
            enregistrer() {
                this.paragrapheAjouter(this.content)
            },
            ajouterVariable(variable) {
                const quill = this.$refs.editeur.quill
                const selection = quill.getSelection(true).index;
                const Delta = Quill.import('delta');
                const d = new Delta()
                    .retain(selection)
                    .insert(`[${variable}]`, {bold: true, color: '#105BFB'})
                    .insert(` `, {bold: false, color: '#000000'})
                quill.updateContents(d)
                quill.setSelection(selection + variable.length + 3);
            }
        }
    }
</script>

<style lang="scss" scoped>
    a.btn {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        margin: 0 !important;
    }

    .content {
        min-height: 200px;
        display: flex;

    }

    .editeur {
        width: 70%;
        height: 500px;
    }
    .saisie-content {
        display: flex;
    }

</style>
