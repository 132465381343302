<template>
    <modal>
        <template slot="header">Initialiser le document</template>
        <template slot="content">
            <div class="content">
                <b>Sélection du modèle</b>
                <p>Sélectionner le modèle parmi les modèles existants</p>

                <ul>
                    <li
                            v-for="modele in modeles"
                            :key="modele.id"
                    >
                        <input
                                type="radio"
                                :id="modele.id"
                                name="modele"
                                @change="() => modeleSelectionne = modele"
                        >
                        <div>
                            <div class="radio"></div>
                        </div>
                        <label :for="modele.id">
                            <b>{{modele.nom}}</b>
                            <p>{{modele.description}}</p>
                        </label>
                    </li>
                </ul>
            </div>
        </template>
        <template slot="footer">
            <a
                    class="btn btn-transparent"
                    target="_blank"
                    @click="annuler"
            >
                Annuler
            </a>
            <a
                    class="btn btn-transparent"
                    target="_blank"
                    @click="() => valider(this.modeleSelectionne)"
            >
                Initialiser
            </a>
        </template>
    </modal>
</template>

<script>
    export default {
        name: 'ModalModelInitialiser',
        props: {
            annuler: Function,
            valider: Function,
            api: Object,
        },
        data() {
            return {
                modeles: [],
                modeleSelectionne: null
            }
        },
        async created() {
            const modeles = await this.api.modelesCharger(this.$document_type)
            this.modeles = modeles.data
        }
    }
</script>

<style lang="scss" scoped>

    @import "~courtisia-style/scss/colors";

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        margin: 10px 0;
        border: 1px solid #cccccc;
        display: flex;

        > label {
            padding: 10px;
            width: 100%;
            margin: 0;
        }

        input[type='radio'] {
            display: none;
        }

        input[type="radio"]:checked + div .radio {
            &::after {
                content: '\2022';
                border-radius: 50%;
                font-size: 35px;
                color: $primary;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        > div {
            min-width: 40px;
            border-right: 1px solid #cccccc;
            display: flex;
            align-items: center;
            justify-content: center;

            .radio {
                position: relative;
                content: '';
                border: 1px solid #cccccc;
                width: 15px;
                height: 15px;
                border-radius: 50%;
            }
        }
    }

</style>
