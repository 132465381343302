<template>
    <div class="base cadre form">
        <div class="container cadre-content">
            <iframe :src="this.get_url()" width="1250px" height="95%" style="margin: auto; padding: 25px;padding-right: 25px;display:block;"></iframe>

            <div class="cadre-pied">
                <router-link
                        :to="{name: 'form'}"
                        tag="button"
                        class="btn btn-transparent"
                >Retour
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from '../api'
    import integer from "vuelidate/src/validators/integer";


    export default {
        name: "preview",
        data() {
            return {
                api: new Api(this.jeton)

            }
        },
        props: {
            jeton: String,
            dossierType: String,
            company_id: integer,

        },
        async created() {
            this.api = new Api(this.$jeton);

        },
        methods: {
            get_url() {
                return process.env.VUE_APP_DOCUMENT_API + this.jeton + '/dossiers/' + this.dossierType + '_PREVIEW' + this.company_id + '/document/' + this.$document_type;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "~courtisia-style/scss/colors";

    .cadre-pied {
        background: $primary;
        position: absolute;
        width: 100%;
        bottom: 0px;
    }

    .chargement {
        width: 100%;
        height: 100%;
        background-color: #cccccc;
        text-align: center;
        padding-top: 200px;
        color: $primary;
        font-size: 25px;
    }


</style>