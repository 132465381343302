<template>
    <modal>
        <template slot="header">Confirmation</template>
        <template slot="content">
            <div class="content">
                <b>ATTENTION</b>
                <p>L'initialisation du MANDAT à partir du modèle choisi écrasera l'ensemble des modifications réalisées au préalable</p>

                <p>Veuillez donc confirmer l'opération</p>
            </div>
        </template>
        <template slot="footer">
            <a
                    class="btn btn-transparent"
                    target="_blank"
                    @click="annuler"
            >
                Annuler
            </a>
            <a
                    class="btn btn-transparent"
                    target="_blank"
                    @click="() => valider()"
            >
                Initialiser
            </a>
        </template>
    </modal>
</template>

<script>
    export default {
        name: 'ModalModelInitialiserConfirm',
        props: {
            annuler: Function,
            valider: Function,
        }
    }
</script>

<style lang="scss" scoped>

    @import "~courtisia-style/scss/colors";

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        margin: 10px 0;
        border: 1px solid #cccccc;
        display: flex;

        > label {
            padding: 10px;
            width: 100%;
            margin: 0;
        }

        input[type='radio'] {
            display: none;
        }

        input[type="radio"]:checked + div .radio {
            &::after {
                content: '\2022';
                border-radius: 50%;
                font-size: 35px;
                color: $primary;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        > div {
            min-width: 40px;
            border-right: 1px solid #cccccc;
            display: flex;
            align-items: center;
            justify-content: center;

            .radio {
                position: relative;
                content: '';
                border: 1px solid #cccccc;
                width: 15px;
                height: 15px;
                border-radius: 50%;
            }
        }
    }

</style>
