<template>
    <div class="courtisia-module documents-commerciaux-container">
        <router-view></router-view>
    </div>
</template>

<script>

    export default {
        name: 'app',
        props: {
            dossierId: String,
            dossierType: String,
            hybrideMandant: Boolean,
            document_type: String
        },
        components: {}
    }
</script>

<style lang="scss">

    @import "assets/scss/main";


</style>
