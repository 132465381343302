<template>
    <div class="base cadre form">
        <div class="container cadre-title">

            <div class="admin-actions">
                <h1 v-if="this.$document_type==='MANDAT'">Personnalisation du mandat</h1>
                <h1 v-if="this.$document_type==='NOTICE'">Personnalisation de la notice</h1>
                <a
                        v-if="this.$admin === '1'"
                        class="btn btn-default"
                        @click="modalModelInitialiser = true"
                >
                    <i class="fas fa-cog"></i>
                    Initialiser à partir d'un modèle
                </a>

                <a
                        v-if="this.$admin === '1'"
                        class="btn btn-default"
                        @click="modalModelSauvegarder = true"
                >
                    <i class="fas fa-file-alt"></i>
                    Enregistrer comme modèle
                </a>
            </div>
            <div class="container cadre-sous-titre">
                <h2 v-if="this.$document_type==='MANDAT'">Edition du MANDAT personnalisé de la société {{companyLibelle}}, pour les dossiers {{$dossierType}}</h2>
                <h2 v-if="this.$document_type==='NOTICE'">Edition de la notice personnalisée de la société {{companyLibelle}}, pour les dossiers {{$dossierType}}</h2>
            </div>
        </div>
        <div class="container cadre-content">
            <div
                    class="cadre-preambule"
            >
                <h3># Préambule</h3>
                <p>Le Préambule est généré automatiquement au début de document</p>
            </div>
            <draggable
                    v-model="paragraphes"
                    group="paragraphs"
                    @start="drag=true"
                    @end="paragrapheDeplacer"
                    draggable=".draggable"
            >
                <div
                        :id="`paragraphe-${paragraphe.identifiant || paragraphe.id}`"
                        class="paragraph"
                        :class="{'draggable': paragraphe.identifiant !== 'titre'}"
                        v-for="paragraphe of paragraphes"
                        :key="paragraphe.id"
                >
                    <i
                            :class="{'no-draggable': paragraphe.identifiant === 'titre'}"
                            class="fas fa-bars"
                    ></i>
                    <div
                            class="cadre-paragraph"
                            v-html="paragraphe.paragraphe"
                    ></div>
                    <div>
                        <div class="button-container">
                            <a
                                    class="btn btn-bordered"
                                    :disabled="!paragraphe.edition"
                                    @click="modalEditer = {...paragraphe}"
                            >
                                <i class="fas fa-edit"></i>&nbsp;Éditer
                            </a>
                        </div>
                        <div class="button-container">
                            <a
                                    class="btn btn-bordered"
                                    :disabled="!paragraphe.suppression"
                                    @click="modalSupprimer = paragraphe"
                            >
                                <i class="fas fa-trash"></i>&nbsp;Supprimer
                            </a>
                        </div>
                    </div>
                </div>
            </draggable>
            <hr/>
            <div
                    class="paragraph paragraph-ajouter"
                    @click="modalAjouter = true"
            >
                <div class="cadre-paragraph">
                    <h2 class="text-primary">
                        <i class="fas fa-edit"></i> &nbsp; Nouveau paragraphe
                    </h2>
                    <p>
                        Ajouter un nouveau paragraphe <br/>
                        éditable et supprimable au document
                    </p>
                </div>
            </div>
            <div
                    class="cadre-annexe"
                    v-if="this.$document_type==='MANDAT'"
            >
                <h3># Annexes</h3>
                <p>Les annexes sont générées automatiquement en fin de document en fonction :</p>
                <ul>
                    <li>De la typologie du projet : Particulier, Professionnel ou Conseil</li>
                    <li>Du nombre d'emprunteurs</li>
                    <li>Des crédits existants</li>
                </ul>
            </div>

            <div class="cadre-pied">
                <router-link
                        :to="{name: 'preview', params: { jeton : this.$jeton, dossierType : this.$dossierType, company_id : this.$company_id}}"
                        tag="button"
                        class="btn btn-transparent"
                >Prévisualiser
                </router-link>
            </div>
        </div>


        <!-- Modals -->
        <modal-editer
                v-if="modalEditer"
                :paragraphe="modalEditer"
                :close="() => modalEditer = null"
                :paragraphe-changer="paragrapheChanger"
                :variables="variables"
                :variables_infos="variables_infos"
        ></modal-editer>
        <modal-ajouter
                v-if="modalAjouter"
                :close="() => modalAjouter = null"
                :paragraphe-ajouter="paragrapheAjouter"
                :variables="variables"
                :variables_infos="variables_infos"
        ></modal-ajouter>
        <modal-supprimer
                v-if="modalSupprimer"
                :paragraphe="modalSupprimer"
                :close="() => modalSupprimer = null"
                :paragraphe-supprimer="paragrapheSupprimer"
        ></modal-supprimer>
        <modal-activer
                v-if="modalActiver"
                :annuler="activationAnnuler"
                :valider="activationActiver"
        ></modal-activer>
        <modal-model-initialiser
                v-if="modalModelInitialiser"
                :api="api"
                :annuler="() => modalModelInitialiser = null"
                :valider="modeleInitialiser"
        >
        </modal-model-initialiser>
        <modal-model-initialiser-confirm
                v-if="modalModelInitialiserConfirm"
                :annuler="() => modalModelInitialiserConfirm = null"
                :valider="modeleInitialiserConfirmer"
        >

        </modal-model-initialiser-confirm>
        <modal-model-sauvegarder
                v-if="modalModelSauvegarder"
                :annuler="() => modalModelSauvegarder = null"
                :valider="modeleSauvegarder"
        >
        </modal-model-sauvegarder>

        <!-- Notifications -->
        <notifications group="success" position="top center"/>
        <notifications group="error" position="top center"/>
    </div>
</template>

<script>

    import draggable from 'vuedraggable'
    import Api from '../api'
    import ModalEditer from "./ModalEditer";
    import ModalSupprimer from "./ModalSupprimer";
    import ModalAjouter from "./ModalAjouter";
    import ModalActiver from "./ModalActiver";
    import ModalModelInitialiser from "./ModalModelInitialiser";
    import ModalModelSauvegarder from "./ModalModelSauvegarder";
    import ModalModelInitialiserConfirm from "./ModalModelInitialiserConfirm";
    import integer from "vuelidate/src/validators/integer";

    export default {
        name: 'Form',
        props: {
            jeton: String,
            dossierType: String,
            company_id: integer,
            admin: integer,
            document_type: String
        },
        components: {
            ModalModelInitialiserConfirm,
            ModalModelSauvegarder,
            ModalModelInitialiser,
            ModalActiver,
            ModalAjouter,
            ModalSupprimer,
            ModalEditer,
            draggable
        },
        data() {
            return {
                api: new Api(this.jeton),
                modalEditer: null,
                modalAjouter: null,
                modalSupprimer: null,
                modalActiver: null,
                modalModelInitialiser: null,
                modalModelInitialiserConfirm: null,
                modalModelSauvegarder: null,
                paragraphes: [],
                variables: [],
                variables_infos: [],
                company: null,
            }
        },
        async created() {
            this.api = new Api(this.$jeton);
            this.company = await this.companyCharger(this.$company_id)

            const editionActive = await this.activationCharger();
            //const editionActive = true
            // Ssi le paramètre n'a jamais été défini, on demande d'activer l'option de personnalisation
            if (editionActive === null) {
                this.modalActiver = true
            }
            if (editionActive === true) {
                this.paragraphesCharger()
                this.variablesCharger()
            }
        },
        computed: {
          companyLibelle(){
              if(this.company){
                  return this.company.lib
              }
              return ''
          }
        },
        methods: {
            notifier(type, groupe, text) {
                this.$notify({
                    type,
                    group: groupe,
                    text
                })
            },
            variablesCharger() {
                return this.api.variablesCharger()
                    .then((res) => {
                        this.variables = res.data[0];
                        this.variables_infos = res.data[1];
                    }).catch(() => {
                        this.notifier('error', 'error', 'Chargement des variables dynamiques impossible')
                    })
            },
            activationCharger() {
                return this.api.editionCharger(this.$document_type)
                    .then(res => res.data)
                    .catch(() => {
                        this.notifier('error', 'error', 'Erreur lors du chargement du paramètre d\'edition')
                    })
            },
            activationAnnuler() {
                // TODO: On doit quitter la fenêtre Courtisia car le courtier ne souhaite pas éditer son mandat
                this.modalActiver = false
            },
            activationActiver() {
                // Le courtier active l'édition du mandat.
                // On cache la modal, on init le modèle standard et on charge les paragraphes
                this.api.editionActiver(this.$document_type).then(() => {
                    this.modalActiver = false
                    return true;
                }).then(() => {
                    return this.api.paragraphesInitialiser(this.$document_type, { reference: 'STANDARD' })
                }).then((res) => {
                    this.paragraphes = res.data.paragraphes.concat(this.paragraphes)
                }).catch(() => {
                    this.notifier('error', 'error', 'Erreur lors de l\'activation du paramètre d\'edition')
                })
            },
            paragraphesCharger() {
                this.api.paragraphesCharger(this.$document_type, this.$dossierType, this.$company_id).then((res) => {
                    this.paragraphes = res.data.concat(this.paragraphes)
                });
            },
            paragrapheAjouter(html) {
                this.api
                    .paragrapheAjouter(this.$document_type, this.$dossierType, this.$company_id, {contenu: html})
                    .then((res) => {
                        if (res.data.status === 'OK') {
                            this.paragraphes.push(res.data.document_template);
                        }
                        this.modalAjouter = null;
                        this.notifier('success', 'success', 'Paragraphe ajouté');
                    })
                    .catch(() => {
                        this.notifier('error', 'error', 'Erreur lors de l\'ajout du paragraphe')
                    })
            },
            paragrapheChanger(paragraphe) {
                this.api
                    .paragrapheChanger(this.$document_type, paragraphe)
                    .then((res) => {
                        if (res.data.status === 'OK') {
                            this.paragraphes = this.paragraphes.map(p => {
                                if (p.id === paragraphe.id) {
                                    return res.data.paragraphe;
                                }
                                return p;
                            })
                        }
                        this.modalEditer = null;
                        this.notifier('success', 'success', 'Paragraphe modifié')
                    }).catch(() => {
                    this.modalEditer = null;
                    this.notifier('error', 'error', 'Erreur lors de la modification du paragraphe')
                })
            },
            paragrapheSupprimer(id) {
                this.api
                    .paragrapheSupprimer(this.$document_type, id)
                    .then((res) => {
                        if (res.data.status === 'OK') {
                            this.paragraphes = this.paragraphes.filter(p => p.id !== id)
                        }
                        this.modalSupprimer = null;
                        this.notifier('success', 'success', 'Paragraphe supprimé')
                    })
                    .catch(() => {
                        this.modalSupprimer = null;
                        this.notifier('error', 'error', 'Erreur lors de la suppression du paragraphe')
                    })
            },
            paragrapheDeplacer() {
                const paragraphes = this.paragraphes.map((p, idx) => ({ id: p.id, ordre: idx + 1 }))

                this.api
                    .paragraphesDeplacer(this.$document_type, paragraphes, this.$dossierType, this.$company_id)
                    .then((res) => {
                        this.paragraphes = res.data
                        this.notifier('success', 'success', 'Paragraphe déplacé avec succès')
                    })
                    .catch(() => {
                        this.notifier('error', 'error', 'Erreur lors du déplacement du paragraphe')
                    })
            },
            modeleInitialiser(modele) {
                this.modeleSelectionne = modele
                this.modalModelInitialiser = null;
                this.modalModelInitialiserConfirm = true;
            },
            modeleInitialiserConfirmer() {
                this.api
                    .paragraphesInitialiser(this.$document_type, this.modeleSelectionne, this.$company_id, this.$dossierType)
                    .then((res) => {
                        this.paragraphes = res.data.paragraphes;
                        this.notifier('success', 'success', 'Paragraphes initialisés');
                        this.modalModelInitialiserConfirm = null;
                    }).catch(() => {
                    this.notifier('error', 'error', 'Erreur lors de l\'initialisation des paragraphes');
                })
            },
            modeleSauvegarder(modele) {
                const paragraphes = this.paragraphes.map((p) => {
                    return {...p, modele: modele.reference, company: 0}
                })
                const apiData = {modele, paragraphes}

                this.api.modeleCreer(apiData, this.$dossierType, this.$document_type).then((res) => {
                    if (res.data.status === 'OK') {
                        this.notifier('success', 'success', 'Le modèle à été sauvegardé')
                    }
                }).catch(() => {
                    this.notifier('error', 'error', 'Erreur lors de l\'enregistrement du modèle');
                })

                this.modalModelSauvegarder = null;
            },
            companyCharger(company_id){
                return this.api.companyCharger(company_id).then(d => d.data)
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "~courtisia-style/scss/colors";

    .base {
        background-color: rgb(250, 250, 250);
    }

    .no-draggable {
        color: gray !important;
    }

    .paragraph {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;
        border-radius: 2px;
        padding: 10px;
        min-height: 100px;

        &#paragraphe-credits {
            .cadre-paragraph {
                background: $primary;
                text-align: center;
                color: white;
                margin-right: 0;

                h2 {
                    color: white;
                }
            }
        }

        &.paragraph-ajouter {
            text-align: center;
            cursor: pointer;
            justify-content: center;
            padding: 0;
            width: 30%;
            margin: auto;
            margin-top: 50px;

            > .cadre-paragraph {
                margin: 0;
                padding-top: 80px;
                padding-bottom: 80px;

                h2 {
                    font-size: 25px;
                }

                p {
                    font-size: 20px;
                }
            }
        }

        .fa-bars {
            margin-right: 20px;
            color: $primary;
        }

        .cadre-paragraph {
            border: 1px solid $gray-light;
            padding: 20px;
            border-radius: 2px;
            margin-right: 20px;
            width: 100%;
        }

        .button-container:first-child {
            margin-bottom: 10px;
        }

        a {
            width: 150px;
            display: inline-flex;
            padding-top: 10px;
        }
    }

    .cadre-pied {
        background: $primary;
        position: absolute;
        width: 100%;
        bottom: 0px;
    }

    .cadre-title {

        h1 {
            margin-bottom: 10px;
        }

        h2 {
            padding-left: 45px;
            color: rgb(66, 66, 66) !important;
            font-size: 25px;
            padding-top: 25px;
        }
    }

    .cadre-annexe {
        width: 80%;
        height: 270px;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 70px;
        padding-left: 25px;
        background-color: $primary;
        font-size: 25px;

        li {
            font-weight: bold;
            color: #eeeeee !important;
        }

        h3 {
            color: #eeeeee !important;
        }

        p {
            color: #eeeeee !important;
        }
    }

    .cadre-preambule {
        width: 80%;
        margin: auto;
        margin-top: 5px;
        padding-left: 25px;
        padding-top: 7px;
        padding-bottom: 20px;
        background-color: $primary;
        font-size: 25px;

        h3 {
            color: #eeeeee !important;
        }

        p {
            color: #eeeeee !important;
        }
    }

    .admin-actions {
        display: flex;
        padding-left: 45px;
        h1 {
            width: 75%;
        }

        a {
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 15px;
            height: 60px;
            width: 250px;
        }
    }

</style>
