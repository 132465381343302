<template>
    <modal>
        <template slot="header">Personnalisation du mandat</template>
        <template slot="content">
            <div class="content">
                <b>Attention</b>
                <hr/>
                <p>Vous êtes sur le point de personnaliser le mandat, la société Fletesia décline toute responsabilité</p>
            </div>
        </template>
        <template slot="footer">
            <!--<button
                    class="btn btn-transparent"
                    target="_blank"
                    @click="annuler"
            >
                Annuler
            </button>-->
            <a
                    class="btn btn-transparent btn-large"
                    target="_blank"
                    @click="valider"
            >
                Je souhaite personnaliser mon mandat
            </a>
        </template>
    </modal>
</template>

<script>
    export default {
        name: 'ModalActiver',
        props: {
            annuler: Function,
            valider: Function,
        }
    }
</script>

<style lang="scss" scoped>


</style>
